import React from "react";
import Lottie from 'react-lottie';
import * as rocketAnimation from '../animations/Astronaut_Ride_Rocket.json'

const Landing = () => {
  const rocketAnimationOption = {
    loop: true,
    autoplay: true,
    animationData: rocketAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div>
      <div className="relative">
        <img className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover h-full w-full" src="images/9.1832117.jpg" alt="" />
        <div className="mx-auto max-w-[1440px] relative overflow-hidden">
          <img className="absolute top-[77px] md:top-[115px] left-[19px] md:left-[202px] w-[43.3px] md:w-auto z-[1]" src="images/star1.svg" alt="" />
          <img className="absolute bottom-[24.85px] md:bottom-[82px] right-[6.97px] md:right-[125px] w-[51.03px] md:w-auto z-[1]" src="images/star2.svg" alt="" />
          {/* Print Bar */}
          {/* <div className="hidden md:flex flex-col items-center space-y-5 absolute top-1/2 left-[48px] -translate-y-1/2 z-[2]">
            <div className="flex justify-center items-center w-[25px] h-[25px] rounded-full bg-white">
              <img src="images/foot.svg" alt="" />
            </div>
            <div className="w-[15px] h-[15px] rounded-full border-2 border-white" />
            <div className="w-[15px] h-[15px] rounded-full border-2 border-white" />
            <div className="w-[15px] h-[15px] rounded-full border-2 border-white" />
            <div className="w-[15px] h-[15px] rounded-full border-2 border-white" />
            <div className="w-[15px] h-[15px] rounded-full border-2 border-white" />
            <div className="w-[15px] h-[15px] rounded-full border-2 border-white" />
            <div className="w-[15px] h-[15px] rounded-full border-2 border-white" />
            <div className="w-[15px] h-[15px] rounded-full border-2 border-white" />
          </div> */}
          <div className="flex justify-between items-center pl-4 pt-4 md:pt-0 md:pl-[48px] pr-4 md:pr-[98.57px] md:h-[100px] relative z-0">
            <div className="flex items-center gap-x-[7px]">
              <img className="w-10" src="images/logo.png" alt="" />
              <span className="font-Showcard text-[22px] leading-[20px] shadow1 stroke1">$TANK</span>
            </div>
            <button className="flex justify-center items-center w-[150px] h-[40px] bg-white hover:bg-blue4 transition border-2 border-black1 rounded-[30px] font-Rubik font-medium text-black1 text-[16px] leading-[150%] shadow-md">
              Buy Now
            </button>
            <div className="hidden md:flex items-center space-x-[33px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <a href="https://t.me/ThomasTheTankBase" target="_blank" rel="noopener noreferrer"> <img className="transition hover:scale-110 cursor-pointer" src="images/socials/1.svg" alt="" /></a>
              <a href="https://x.com/TTTonBase" target="_blank" rel="noopener noreferrer"> <img className="transition hover:scale-110 cursor-pointer" src="images/socials/2.svg" alt="" /> </a>
              <img className="transition hover:scale-110 cursor-pointer" src="images/socials/4.svg" alt="" />
              <img className="transition hover:scale-110 cursor-pointer" src="images/socials/5.svg" alt="" />
            </div>
          </div>
          <div className="flex flex-col items-center px-4 pt-[49.53px] md:pt-[19px] pb-[68.29px] md:pb-[58.25px] relative z-0">
            <span className="font-Showcard text-[30px] leading-[50px] shadow2 uppercase stroke1">Say Hello to</span>
            <span className="font-Showcard text-[100px] leading-[91.5px] text-white stroke3">$TANK</span>
            <br />
            <span className="font-Showcard text-[60px] text-center leading-[91.5px] text-white stroke3">Thomas The Tank</span>

            <div className="mt-[13.96px] md:mt-[35px] relative">
              {/* <img className="hidden md:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[1138px]" src="images/coins.svg" alt="" />
              <img className="md:hidden absolute top-[90%] left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[462.72px]" src="images/coins-mobile.svg" alt="" /> */}
              <img className="w-[185px] h-[152px] md:w-auto md:h-auto relative invisible" src="images/logo.png" alt="" />
            </div>
            <span className="mt-[37px] font-Rubik font-bold text-[14px] md:text-[16px] leading-[19px] md:leading-[150%] max-w-[485px] text-center relative shadow2 uppercase stroke1">Join us in celebrating our love for Thomas and his Friends, iconic symbols of Base degens. Let's cheer on Thomas as he embarks on his thrilling journey! Go Thomas, go!</span>
            <div className="flex text-[16px] md:text-[20px] leading-[26px] md:leading-[32.98px] space-x-2 mt-5 md:mt-[15.2px] relative">
              <span>Built On</span>
              <img src="images/base.svg" alt="" width="25" height="25" />
              <span className="font-bold">Base</span>
            </div>
            <a href="https://t.me/ThomasTheTankBase" target="_blank" rel="noreferrer" className="flex justify-center items-center w-[175px] h-[40px] bg-white hover:bg-blue4 transition border-2 border-black1 rounded-[30px] font-Rubik font-medium text-black1 text-[16px] leading-[150%] shadow-md mt-5 space-x-1 relative">
              <img src="images/white-tg.svg" alt="" />
              <span>Join Telegram</span>
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className="mx-auto max-w-[1440px] relative overflow-hidden">
          <div className="flex flex-col-reverse mobile:flex-row justify-center items-center px-4 md:px-[100px] pt-[60px] pb-[69px] xl:py-[118px]">
            <div className="hidden xl:flex flex-col space-y-[44px] mt-0 relative">
              <img className="absolute bottom-[-0px] right-[-0px] w-[270px]" src="images/train-on-railway.png" alt="" />
              <div className="flex items-center space-x-5 p-3 bg-blue2 rounded-2xl w-[464px] h-[104px]">
              <img src="images/life/1.png" alt="" width="80" height="80" />
                <span className="font-medium text-[24px] leading-[133.3333%]">Built on Base</span>
              </div>
              <div className="flex items-center space-x-5 p-3 bg-blue2 rounded-2xl w-[464px] h-[104px]">
                <img src="images/life/2.svg" alt="" />
                <span className="font-medium text-[24px] leading-[133.3333%]">0% Buy Tax</span>
              </div>
              <div className="flex items-center space-x-5 p-3 bg-blue2 rounded-2xl w-[464px] h-[104px]">
                <img src="images/life/3.svg" alt="" />
                <span className="font-medium text-[24px] leading-[133.3333%]">0% Sell Tax</span>
              </div>
            </div>
            <div className="flex flex-col xl:ml-[156px]">
              <span className="font-bold text-[30px] md:text-[32px] leading-[125%]">Welcome to the Train Life 🚂</span>
              <div className="flex xl:hidden flex-col space-y-5 mt-[22px] w-full relative">
                <img className="absolute bottom-[-32.56px] right-[-12px] w-[200px]" src="images/train-on-railway.png" alt="" />
                <div className="flex items-center space-x-[14.78px] p-[8.87px] bg-blue2 rounded-2xl h-[76.88px]">
                  <img className="w-[59.14px] h-[59.14px]" src="images/life/1.png" alt="" />
                  <span className="font-medium text-[18px] leading-[133.3333%]">Built on Base</span>
                </div>
                <div className="flex items-center space-x-[14.78px] p-[8.87px] bg-blue2 rounded-2xl h-[76.88px]">
                  <img className="w-[59.14px] h-[59.14px]" src="images/life/2.svg" alt="" />
                  <span className="font-medium text-[18px] leading-[133.3333%]">0% Buy Tax</span>
                </div>
                <div className="flex items-center space-x-[14.78px] p-[8.87px] bg-blue2 rounded-2xl h-[76.88px]">
                  <img className="w-[59.14px] h-[59.14px]" src="images/life/3.svg" alt="" />
                  <span className="font-medium text-[18px] leading-[133.3333%]">0% Sell Tax</span>
                </div>
              </div>
              <span className="mt-[66.12px] xl:mt-3 text-[14px] md:text-[18px] leading-[24px] md:leading-[133.3333%] xl:max-w-[598px] text-blue3">
    Yay! Thomas The Tank Token is a fun meme token meant for people to reminisce over their childhood and connect with Thomas and Friends. It's a delightful way to engage with the nostalgia of the past.
    <br/><br/>Thomas The Tank Token brings the joy of childhood memories without any tax or rewards structure. It's all about reliving those wonderful moments from the past and sharing the love for Thomas and Friends.
</span>

            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mx-auto max-w-[1440px] relative overflow-hidden">
          <div className="flex flex-col items-center px-4 pt-[25px] md:pt-[100px] pb-[54px] md:pb-[100px]">
            <span className="font-bold text-[30px] md:text-[32px] leading-[40px] md:leading-[125%] self-start md:self-center">How Does $TANK work?</span>
            <span className="mt-[15px] md:mt-3 md:max-w-[845px] text-blue3">Every interaction with $TANK brings the magic of childhood memories without any bonus structures. Thomas The Tank Token is designed to evoke nostalgia and connect people with the beloved characters of Thomas and Friends. So, each trade is a delightful journey through cherished moments, shared experiences, together on Base.</span>
            
          </div>
        </div>
      </div>
      <div className="bg-blue5 relative">
        <img className="xl:hidden absolute top-0 left-0 w-full h-full object-cover" src="images/sticker.webp" alt="" />
        <img className="hidden xl:block absolute top-0 left-0 w-full h-full object-cover" src="images/sticker.webp" alt="" />
        <div className="mx-auto max-w-[1440px] relative overflow-hidden">
          <div className="flex flex-col items-center pt-[50px] pb-[66px] xl:py-[98px] relative overflow-hidden">
            <span className="font-bold text-[30px] xl:text-[50px] leading-[118.5%] mb-[26px] xl:mb-10">Roadmap</span>
            <div className="flex flex-col xl:flex-row justify-center items-center space-y-[25px] xl:space-y-0 xl:space-x-[30px] relative z-[1]">
              <div className="flex flex-col items-start py-[23px] px-[30px] w-[343px] xl:w-[360px] min-h-[264px] bg-blue4 rounded-[30px] border-2 border-black1 shadow-1xl">
                <div className="px-[18.5px] py-[5px] font-bold font-Inter text-[25px] leading-[121.04%] text-blue4 bg-black1 rounded-[10px]">Phase 1</div>
                <ul className="mt-2 -ml-5 text-[16px] leading-[175%]">
                  <li>Website Completed</li>
                  <li>Community Created</li>
                  <li>KOL Partnership</li>
                  <li>$TANK Listed on Uniswap</li>
                  <li>$TANK Liquidity Locked for 6 Months</li>
                </ul>
              </div>
              <div className="flex flex-col items-start py-[23px] px-[30px] w-[343px] xl:w-[360px] min-h-[264px] bg-blue4 rounded-[30px] border-2 border-black1 shadow-1xl">
                <div className="px-[18.5px] py-[5px] font-bold font-Inter text-[25px] leading-[121.04%] text-blue4 bg-black1 rounded-[10px]">Phase 2</div>
                <ul className="mt-2 -ml-5 text-[16px] leading-[175%]">
                  <li>Influencer marketing begins</li>
                  <li>$TANK listed on CoinMarket & Coingecko</li>
                  <li>$TANK Listed on T2 CEX</li>
                  <li>$TANK reaches 2,500 Holders</li>
                </ul>
              </div>
              <div className="flex flex-col items-start py-[23px] px-[30px] w-[343px] xl:w-[360px] min-h-[264px] bg-blue4 rounded-[30px] border-2 border-black1 shadow-1xl">
                <div className="px-[18.5px] py-[5px] font-bold font-Inter text-[25px] leading-[121.04%] text-blue4 bg-black1 rounded-[10px]">Phase 3</div>
                <ul className="mt-2 -ml-5 text-[16px] leading-[175%]">
                  <li>$TANK reaches 5,000 Holders</li>
                  <li>Crypto.com & Coinbase price listings</li>
                  <li>CEX Listing Announcement</li>
                  <li>Liquidity pool grows thicker</li>
                  <li>Launch Youtube/TikTok marketing campaign</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mx-auto max-w-[1440px] relative overflow-hidden">
          <div className="flex flex-col items-start md:items-center px-4 pt-[50px] md:pt-[80px] pb-[52px] md:pb-[96px]">
            <span className="font-bold text-[30px] md:text-[32px] leading-[40px] md:leading-[125%]">Tank-o-nomics</span>
            <span className="mt-3 mb-5md:mb-10 text-[16px] leading-[150%] text-blue3 md:text-center">The $TANK Token Economy is designed to drive ecosystem activity.</span>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[84px] gap-y-0 md:gap-y-9 -ml-7 md:ml-0">
              <div className="flex md:flex-col items-start md:items-center">
                <img src="images/tokenomics/1.png" alt="" />
                <div className="flex flex-col items-start md:items-center">
                  <span className="mt-8 md:mt-0 text-[18px] leading-[133.3333%] md:text-center">75% - LP + 20% Burn</span>
                  <span className="mt-1 text-[16px] leading-[150%] max-w-[314px] md:text-center text-blue3">75% of the token supply will be put into liquidity. 20% will be burnt.</span>
                </div>
              </div>
              <div className="flex md:flex-col items-start md:items-center">
                <img src="images/tokenomics/2.png" alt="" />
                <div className="flex flex-col items-start md:items-center">
                  <span className="mt-8 md:mt-0 text-[18px] leading-[133.3333%] md:text-center">5% - KOLs</span>
                  <span className="mt-1 text-[16px] leading-[150%] max-w-[314px] md:text-center text-blue3">5% will be spent on KOL marketing to send your money higher.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-blue5 relative">
      </div>
    </div>
  );
};

export default Landing;