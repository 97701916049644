import React, { useContext } from "react";
import { RefContext } from "../contexts/RefContextProvider";

const Footer = () => {
  const { footerRef } = useContext(RefContext);
  return (
    <footer className="flex flex-col items-center pt-[56px] md:pt-12 pb-[62px] md:pb-[45px] gap-y-10 md:gap-y-[33px] bg-black2 text-center" ref={footerRef}>
      <div className="hidden md:flex flex-wrap justify-center items-center gap-[64px]">
        <img src="images/partners/2.svg" alt="" />
        <img src="images/partners/3.svg" alt="" />
        <img src="images/partners/4.svg" alt="" />
      </div>
      <div className="flex md:hidden flex-wrap justify-center items-center gap-5 mx-[68px]">
        <img src="images/partners/2-mobile.svg" alt="" />
        <img src="images/partners/3-mobile.svg" alt="" />
        <img src="images/partners/4-mobile.svg" alt="" />
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center gap-[10px] md:gap-[15px]">
        <div className="flex items-center gap-x-[7px]">
          <img className="w-[32px]" src="images/logo.png" alt="" />
          <span className="font-Showcard text-[22px] leading-[20px] shadow1">$TANK</span>
        </div>      
        <span className="text-[15px] leading-[160%] 2xl:text-[1.04166vw]">@2024 Thomas The Tank Token. All Rights Reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
